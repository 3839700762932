<template>
  <Layout>
    <WithLocation
      v-slot="{
        isLocationLoading,
        theLocation,
        theFeaturedImage,
        notFound,
      }"
      :slug="locSlug"
    >
      <BaseLoader v-if="isLocationLoading" bg-color="#fff" color="#6a8ed1"
        ><h3 class="text-xs-center mt-4 bloak-light-blue--text"
          >Loading the menu...</h3
        ></BaseLoader
      >
      <div v-else-if="!theLocation || notFound" class="not-found">
        <VContainer
          class="bloak-light-blue"
          style="padding-top:80px !important;padding-bottom:80px !important;"
          fluid
          px-0
          pa-3
        >
          <VLayout justify-center>
            <VFlex xs11 sm8>
              <h2 class="header-1"
                >Sorry, but we couldn't find that location!</h2
              >
              <h3 class="body"
                >Please head to our homepage or contact us if you need
                assistance.</h3
              >
            </VFlex>
          </VLayout>
        </VContainer>
      </div>
      <div v-else class="single-location">
        <BaseThemeComponent
          name="SingleLocationHeader"
          :bg-image="theFeaturedImage ? theFeaturedImage : ''"
          :title="theLocation.title"
          :location="theLocation"
        />
        <BasePageSection
          v-if="theLocation.status !== 'coming_soon'"
          id="menu"
          py-2
          fluid
          full-width
          class="blue-oak-menu-section"
        >
          <VLayout row justify-center>
            <VFlex xs12>
              <BaseLocationContent
                content-key="menu_title"
                default-content="Our Menu"
                tag-class="header-4 text-xs-center mb-4 bloak-blue--text"
                tag="h1"
              /> </VFlex
          ></VLayout>
          <WithLayout
            v-slot="{ isLayoutLoading, theLayout }"
            slug="blue-oak-web-menu"
          >
            <LayoutGrid
              v-if="!isLayoutLoading"
              :location="theLocation"
              :layout="theLayout"
              class="blue-oak-web-menu"
            />
          </WithLayout>
          <!-- <BaseLocationMenus :location="theLocation" /> -->
        </BasePageSection>
        <BaseThemeComponent name="PageSection">
          <VLayout row justify-center>
            <VFlex xs12 md8>
              <BaseLocationContent
                content-key="cta_title"
                default-content="Call Now to Place Your Order!"
                tag-class="header-4 bloak-section--title"
                tag="h1"
              />

              <h3 class="header-2 text-xs-left pt-3">
                <a :href="`tel:${formatPhone('(256) 900-7427')}`"
                  >256-900-7427 (RIBS)</a
                >
              </h3>
            </VFlex></VLayout
          >
        </BaseThemeComponent>
        <BaseThemeComponent name="CateringCTA" />
        <BaseThemeComponent name="SectionLocations" />
      </div>
    </WithLocation>
    <BaseMenuComponent name="MenuSnackbar" />
  </Layout>
</template>

<script>
import Layout from '@utils/get-layout'
import formatPhone from '@utils/format-phone'
import LayoutGrid from '@components/Layout/LayoutGrid'

export default {
  name: 'BlueOakHSVSingleLocation',
  components: { Layout, LayoutGrid },
  props: {
    locSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showEmailModal: false,
    }
  },
  methods: {
    formatPhone,
    excludeThisLocation(loc) {
      return loc.slug !== this.$route.params.locSlug
    }, // excludeThisLocation
  },
}
</script>

<style lang="scss">
@import '@design';

.single-location--header {
  @media only screen and (max-width: 321px) {
    .v-btn {
      height: 38px;
      padding: 10px !important;
    }
  }
}

.single-location {
  .featured-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
}

.blue-oak-web-menu {
  &.grid {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1200px) {
      grid-template-areas: '. left center .' '. right . .' !important;
      grid-template-columns: 1fr 4fr 4fr 1fr !important;
    }

    @media (max-width: 900px) {
      grid-template-areas: 'left center' 'right .' !important;
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 650px) {
      grid-template-areas: 'left' 'center' 'right' !important;
      grid-template-columns: 1fr !important;
      .region {
        margin: 0 !important;
      }
    }
  }
}

.blue-oak-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }
  @media (max-width: 650px) {
    padding: 3px;
  }
}
</style>
